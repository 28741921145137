import { useEffect, useState } from "react";
import "../game3/index.css";
import Slider from "components/progress/bar";
import axiosClient from "config/apiClient";
import { useGameStore } from "../../store/store";
import { useLocation, useNavigate } from "react-router-dom";
import { IoTriangle } from "react-icons/io5";
import { useAuth } from "../../config/AuthDataProvider";
import { toast } from "react-toastify";
import { roundOff } from "../../config/utils";
import React from "react";
import initialimage from "../../assets/game3/bar_frame1.jpg";
import OptimizedImageCarousel from "./imageCarousel";

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* @ts-ignore */

export const Game3 = () => {
  let {
    game3data,
    setGame3Data,
    timeData,
    setTimeData,
    Incrementindex,
    Index,
    setIndex,
    optionData,
    setOptionData,
    ResetIndex,
    Workshop,
    authData,
    isGameOver,
    setIsGameOver,
  } = useGameStore();
  const [load, setLoaded] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(4.5);
  const [lastSelectedPrice, setLastSelectedPrice] = useState(4.5);
  const [displaydiv, setDisplaydiv] = useState(false);
  const [close, setclose] = useState(false);
  const [loading, Setloading] = useState(true);
  const [isBlurActive, setIsBlurActive] = useState(false);
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const isPlayable = location.pathname.includes("play");
  const navigateToLeaderBoard = () => {
    if(isPlayable){
      navigate("/play/leaderboard");
    }else{
      navigate("/leaderboard");
    }
  };

  const fetchOptions = async () => {
    try {
      const resp = await axiosClient.get(
        isPlayable
          ? `/workshop/elasticity/?acronym=${Workshop.acronym}&id=${Workshop.configs.price_elasticity_id}`
          : "/workshop/elasticity/"
      );
      const initialOptions = resp.data.price;
      setOptionData(initialOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async (timeval) => {
    try {
      const resp = await axiosClient.post(isPlayable?`workshop/initial/game/metrics/?acronym=${Workshop.acronym}&id=${Workshop.configs.price_elasticity_id}`:"workshop/initial/game/metrics/",
        // team_name: isPlayable ? Workshop?.team_name : authData?.teamname,
        // // rounds: RoundTracker,
        isPlayable ? { team_name: Workshop?.team_name } : { team_name: authData?.teamname,  ...(game3data?.rounds && {rounds: game3data.rounds}) }
      );
      
      let initialData = resp.data;
      initialData.time = initialData.time.replace("02:", "14:");
      initialData.time = initialData.time.replace("01:", "13:");
      const lastTime = initialData.last_time[initialData.last_time.length - 1];
      if (initialData && timeval && initialData.time !== lastTime) {
        const matchingTime = timeval.find(
          (data) =>
            (initialData.gm_flag ? !data.skip : data.skip) &&
            data.backend_time === initialData.time
        );
        let currentindex = timeval.indexOf(matchingTime);
        setIndex(currentindex + 1);
        if (matchingTime) {
          if (
            initialData.last_time.includes(initialData.time) &&
            initialData.last_time[initialData.last_time.length - 1] !==
              timeval[Index].backend_time
          ) {
            initialData.revenue = "0";
          }
          initialData.time = timeval[currentindex].ui_time;
        }
        setGame3Data(initialData);
        if (initialData.first_time[0] === initialData.time) {
          ResetIndex();
          setTrigger(false);
          setDisplaydiv(false);
          setclose(false);
          setIsGameOver(false);
        }
      } else {
        console.log("else blocks")
        console.log(timeval)
        if (timeval) {
          const lastIndex = timeval.indexOf(timeval[timeval.length - 1]);
          initialData.time = timeval[lastIndex].ui_time;
          setGame3Data(initialData);
          setIndex(lastIndex);
          setclose(true);
          // setIsGameOver(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchTime = async () => {
    try {
      const time = await axiosClient.get(isPlayable ? `/workshop/patron/?acronym=${Workshop.acronym}&id=${Workshop.configs.patron_id}` : "/workshop/patron/");
      const last_value = time.data.display_helper[time.data.display_helper.length - 1]
      time.data.display_helper.push(last_value);
      setTimeData(time.data.display_helper);
      fetchData(time.data.display_helper);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchImages = async () => {
    const imagesList = [];
    for (let i = 1; i <= 28; i++) {
      imagesList.push(require(`../../assets/game3/bar_frame${i}.jpg`));
    }
    setImages(imagesList);
  };

  // if (displaydiv) {
  //   toast.error(
  //     <>
  //       <div className="font-Helvetica font-bold tracking-wide">
  //         You ran out of beer inventory!
  //       </div>
  //     </>,
  //     {
  //       toastId: "end1",
  //       position: "top-center",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: false,
  //       pauseOnHover: false,
  //       draggable: false,
  //       progress: undefined,
  //       theme: "colored",
  //     }
  //   );
  // }

  const handleClick = (e) => {
    e.preventDefault();
    updateFunction();
    setTrigger(true);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedPrice(parseFloat(selectedValue));
    localStorage.setItem(`user_${isPlayable ? Workshop?.team?.name:authData?.username}_price`, selectedValue);
  };

  const updateFunction = async () => {
    let gameEndTime = game3data.last_time[game3data.last_time.length - 1];
    try {
      const currentRound = game3data.rounds;
      // Navigate to congrats screen
      if (timeData.length === Index) {
        setTrigger(false);
        setclose(true);
        setIsGameOver(true);
        return;
      }
      // Skip Post call if same time period occurs (10, 12 etc)
      if (timeData[Index].skip) {
        Incrementindex();
        setGame3Data({
          ...game3data,
          time: timeData[Index].ui_time,
        });
      }

      let payloadTime = timeData[Index].backend_time;
      let response;
      if(isPlayable)
      {
        response = await axiosClient.post(`/workshop/play/game/?acronym=${Workshop.acronym}`, {
          elasticity_id: Workshop.configs.price_elasticity_id,
          user_id: Workshop.team.user_id,
          price: selectedPrice,
          time: payloadTime,
          rounds: currentRound,
        });
      }else{
        response = await axiosClient.post(`/workshop/play/game/`, {
          price: selectedPrice,
          time: payloadTime,
          rounds: currentRound,
        });
      }
      if (
        game3data.last_time.includes(payloadTime) &&
        gameEndTime !== payloadTime
      ) {
        setTrigger(false);
        setTimeout(navigateToLeaderBoard, 2000);
      }

      if (gameEndTime === payloadTime) {
        setTrigger(false);
        setclose(true);
        setIsGameOver(true);
      }
      Incrementindex();
      let updatedGame3 = response.data;
      if (game3data.first_time.includes(payloadTime)) {
        updatedGame3.time = timeData[Index].ui_time;
      }
      updatedGame3.time = timeData[Index].ui_time;
      game3data.first_time.includes(payloadTime)
        ? (updatedGame3.time = timeData[Index + 1].ui_time)
        : (updatedGame3.time = timeData[Index].ui_time);
      setGame3Data(updatedGame3);
    } catch (error) {
      console.error("Error sending data to the server:", error);
    }
  };

  useEffect(() => {
    if (!game3data) {
      return;
    }
    let timeData_index = timeData.length === Index ? Index - 1 : Index;
    let gameEndTime = game3data.last_time[game3data.last_time.length - 1];
    if (
      game3data.ending_inventory > 0 &&
      trigger &&
      gameEndTime !== game3data.time
    ) {
      const update = setInterval(updateFunction, 2000);
      return () => {
        clearTimeout(update);
      };
    } else {
      if (game3data.ending_inventory <= 0) {
        setDisplaydiv(true);
        setIsGameOver(true);
      }
      if (gameEndTime === timeData[timeData_index].backend_time) {
        setclose(true);
      }
    }
  }, [trigger, selectedPrice, Index, game3data, setGame3Data]);

  useEffect(() => {
    // fetchData();
    // fetchTime();
    // fetchOptions();
    // timeData.forEach((element, index) => {
    //   if (game3data.time === element.backend_time) {
    //     if (
    //       game3data.first_time.includes(game3data.time) ||
    //       element.ui_time ===
    //         game3data.last_time[game3data.last_time.length - 1]
    //     ) {
    //       setIndex(index);
    //     } else {
    //       setIndex(index + 1);
    //     }
    //   }
    // });
    //fetchInitialRequests();
    const callback = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.code === "KeyC") {
        const result = window.confirm("Would you like to stop the Auto play?");
        if (result) {
          setTrigger(false);
        } else {
          setTrigger(true);
        }
      }
    };
    document.addEventListener("keydown", callback);

    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, []);

  const fetchInitialRequests = async () => {
    try {
      Setloading(true);
      await fetchTime();
      await fetchOptions();
      // await fetchData();
      await fetchImages();
      Setloading(false);
    } catch (error) {
      Setloading(false);
      console.error("Error fetching initial requests:", error);
    }
  };
  useEffect(() => {
    fetchInitialRequests();
  }, []);

  useEffect(() => {
    if ((authData && authData.username) || isPlayable) {
      const lastSelectedPriceFromLocalStorage = localStorage.getItem(
        `user_${isPlayable ? Workshop?.team?.name:authData?.username}_price`
      );
      if (lastSelectedPriceFromLocalStorage) {
        const parsedPrice = parseFloat(lastSelectedPriceFromLocalStorage);
        if (!isNaN(parsedPrice)) {
          setLastSelectedPrice(parsedPrice);
          setSelectedPrice(parsedPrice);
        }
      }
    }
  });

  return (
    <>
      <div className="relative flex flex-col items-center justify-center lg-max:hidden">
        {/* {Timeloading || Optionsloading || Dataloading ? ( */}
        {loading ? (
          <div className="flex h-screen w-[130vh] items-center justify-center">
            <div className="border-white-600 mr-4 h-10 w-10 animate-spin rounded-full border-t-2"></div>
          </div>
        ) : (
          <div className="w-[130vh]">
            <div className="relative border-2 border-solid">
              {/* <img
                src={require(`../../assets/game3/bar_frame${
                  close ? 1 : Index + 1
                }.jpg`)}
                alt=""
                onLoad={() => setLoaded(true)}
              /> */}
              {/* {images.map((src, index) => (
                <React.Fragment key={index}>
                  {index === Index && !close ? (
                    <img src={src} alt={`Image ${index}`} onLoad={() => setLoaded(true)}/>
                  ) 
                  : index === Index && close ? (
                  <img src={initialimage} alt={`Image ${index}`} onLoad={() => setLoaded(true)}/>
                  )
                  : null}
                </React.Fragment>
              ))} */}
              <OptimizedImageCarousel
                images={images}
                initialImage={initialimage}
                currentIndex={Index}
                setCurrentIndex={setIndex}
                close={close}
                setLoading={setLoaded}
              />
              <div
                className="absolute right-[7%] top-[16.5%] flex 
             h-3 w-[15.4%] items-center bg-[#202020]"
              >
                <div className="font-ericsson h-3 w-1/2 bg-[#202020] px-1.5 text-[1.6vh] tracking-wide text-[#eda64f]">
                  BEER
                </div>
                <div className="font-ericsson h-3 w-1/5 bg-[#202020] pl-[0.6rem] pt-[0.2rem] text-[0.9vh] tracking-wide text-[#eda64f]">
                  <IoTriangle className="text-[#55c11a]" />
                </div>
                <div className="font-ericsson h-3 w-1/3 bg-[#202020] text-[1.5vh] tracking-wider text-[#eda64f]">
                  ${lastSelectedPrice?.toFixed(2) ?? selectedPrice.toFixed(2)}
                </div>
              </div>

              <div
                id="time"
                className="absolute right-0 top-0 font-Helvetica text-5xl font-bold text-[#ffff00]"
              >
                {game3data?.time?.includes("13:")
                  ? game3data.time.replace("13:", "01:")
                  : game3data?.time?.includes("14:")
                  ? game3data.time.replace("14:", "02:")
                  : game3data?.time ?? "08:00"}
              </div>

              {isBlurActive && (
                <div className="fixed inset-0 z-50 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]"></div>
              )}
              {close ? (
                <>
                  <div className="z-index fixed inset-0 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]">
                    <div
                      className="flex flex-col items-center gap-6 rounded-[20px] border-2 border-[#F0B424] bg-[#FFF8EC] px-10 pb-6 pt-8"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="flex flex-row items-start justify-start gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1}
                          stroke="#ECB83D"
                          className="h-16 w-20"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
                          />
                        </svg>
                        <div className="flex flex-col gap-2">
                          <span className="text-xl font-semibold  tracking-wide text-[#2F3F53]">
                            Congratulations!
                          </span>
                          <span className="text-lg  font-medium tracking-wider text-[#2F3F53]">
                            It's{" "}
                            {game3data?.time?.includes("13:")
                              ? game3data.time.replace("13:", "01:")
                              : game3data?.time?.includes("14:")
                              ? game3data.time.replace("14:", "02:")
                              : game3data?.time ?? "02:00"}{" "}
                            now! The Bar is Closed
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center gap-3">
                        <button
                          className="rounded-md bg-[#F0B424] px-5 py-[3px] text-lg font-semibold text-white shadow-md hover:bg-[#ead787] "
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateToLeaderBoard();
                            setIsGameOver(true);
                          }}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
                </>
              ) : null}
              {displaydiv ? (
                <>
                  <div className="z-index   fixed inset-0 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]">
                    <div
                      className="inventoryzero-btn flex flex-col items-center gap-6 rounded-[20px] border-2 border-[#d42525] px-10 pb-6 pt-8"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="flex flex-row items-start justify-start gap-2">
                        <div className="flex flex-col gap-2">
                          <span className="text-xl font-bold  tracking-wide text-[#FFFF]">
                            You ran out of beer inventory!
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center gap-3">
                        <button
                          className="button-class rounded-md px-5 py-[3px] text-lg font-semibold text-white shadow-md "
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateToLeaderBoard();
                            setIsGameOver(true);
                          }}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
                </>
              ) : null}
            </div>
            <div className="flex w-full items-center justify-center gap-2 py-2">
              <div
                className={
                  displaydiv || close
                    ? `hidden`
                    : `w-86 flex items-center justify-evenly`
                }
              >
                <label
                  htmlFor="price"
                  className="me-3 p-2 font-poppins text-lg font-bold  text-white"
                >
                  Select Your Price
                </label>
                <select
                  id="price-dropdown"
                  className="text-md -ml-2 h-[2rem] w-20 rounded-md bg-[#D8D8D852] px-2 font-poppins font-bold text-[#ffff00]"
                  value={
                    lastSelectedPrice?.toFixed(1) ?? selectedPrice.toFixed(1)
                  }
                  onChange={handleSelectChange}
                  disabled={trigger}
                >
                  {optionData?.map((val:number,index) => (
                      <option key={index} value={val.toFixed(1)}>
                        {val.toFixed(1)}
                      </option>
                    )) || <option value="4.5">4.5</option> }
                </select>
              </div>
              <button
                id="enter-btn"
                onClick={handleClick}
                disabled={trigger}
                className={
                  displaydiv || close
                    ? `hidden`
                    : `align-center h-[2.1rem] w-28  rounded-md bg-gradient-to-br from-[#279DF3]  to-[#0561a2] text-center font-Helvetica font-black text-white shadow-xl hover:shadow-lg hover:shadow-[#3072D6] lg:text-lg xl:text-lg`
                }
              >
                ENTER
              </button>
            </div>
            <div className="border-sky-500 justify-even flex border-2 border-solid bg-[#ffffff8a]">
              <div className="flex-1 flex-col text-center">
                <h3 className="pb-5 text-center font-Helvetica text-xl font-black uppercase text-[#132c69]">
                  INVENTORY
                </h3>
                <div id="inventory">
                  <Slider
                    load={load}
                    value={game3data?.ending_inventory ?? 0}
                  />
                </div>
              </div>
              <div className="flex-1 flex-col text-center">
                <h3 className="pb-5 text-center  font-Helvetica  text-xl font-black uppercase text-[#132c69]">
                  REVENUE
                </h3>
                <div className="font-Helvetica text-[4.5vh] font-black text-[#ffff00]">
                  ${game3data?.revenue ? roundOff(game3data.revenue) : 0}
                </div>
                <div></div>
              </div>
              <div className="flex-1 flex-col text-center">
                <h3 className="pb-5 text-center font-Helvetica  text-xl font-black uppercase text-[#132c69]">
                  CUMULATIVE REVENUE
                </h3>
                <div className="font-Helvetica text-[4.5vh] font-black text-[#ffff00]">
                  $
                  {game3data?.cumulative_revenue
                    ? roundOff(game3data.cumulative_revenue)
                    : 0}
                </div>
                <div></div>
              </div>
              {loading ? (
                null
              ):(
                <div className="flex align-items-center absolute bottom-0 left-0">
                <div className="circle">
                  <p className="round-text"> Round </p>
                  <p className="round-number">{game3data?.rounds}</p>
                </div>
              </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="flex h-screen items-center justify-center text-center text-3xl font-semibold text-white md-max:visible lg-min:hidden">
        Please Rotate Your Screen
      </div>
    </>
  );
};
