import CreateTenantForm from "views/systemadmin/client/Components/CreateClient";
import TenantIndexPage from "views/systemadmin/client";
import ConfigurationIndexPage from "views/systemadmin/configuration";
import CreateConfigurationForm from "views/systemadmin/configuration/Components/CreateConfiguration";
import WorkshopIndexPage from "views/systemadmin/workshop";
import CreateWorkShopForm from "views/systemadmin/workshop/Components/CreateWorkShop";
import UserIndexPage from "views/systemadmin/user";
import PermissionManagement from "views/systemadmin/user/Components/CreateUserRoles";
import { ClientListings } from "views/systemadmin/client/Components/ClientListings";
import ViewClient from "views/systemadmin/client/Components/ViewClient";
import { ConfigurationListing } from "views/systemadmin/configuration/Components/ConfigurationListing";
import ViewConfiguration from "views/systemadmin/configuration/Components/ViewConfiguration";
import WorkshopListing from "views/systemadmin/workshop/Components/WorkshopListing";
import ViewWorkshop from "views/systemadmin/workshop/Components/ViewWorkshop";
import CreateUserForms from "views/systemadmin/user/Components/CreateUserForms";
import { UserListing } from "views/systemadmin/user/Components/UserListing";
import ViewUser from "views/systemadmin/user/Components/ViewUser";
import About from "views/about";
import Leaderboard from "views/game3/leaderboard/leaderboard";
import Alerts from "views/instructor/Alerts";
import { Game3 } from "views/game3";
import UnAuthorisedPage from "components/pages/UnauthorisedPage";
import Game1A from "views/game1/game1A/game1A";
import Game1B from "views/game1/game1B";
import Play from "views/game2";
import InsightsBox from "views/game3/leaderboard/insights";

const mainRoutes = [
  {
    name: "About",
    layout: "/game",
    path: "about",
    component: <About />,
    protected: false,
  },
  {
    name: "Leaderboard",
    layout: "/game",
    path: "leaderboard",
    component: <Leaderboard />,
    protected: true,
  },
  {
    name: "insights",
    layout: "/game",
    path: "insights",
    component: <InsightsBox />,
    protected: true,
  },
  {
    name: "Game1",
    layout: "/game",
    path: "game1A",
    component: <Game1A />,
    protected: true,
  },
  {
    name: "Game2",
    layout: "/game",
    path: "game2",
    component: <Play />,
    protected: true,
  },
  {
    name: "game1B",
    layout: "/game",
    path: "game1B",
    component: <Game1B />,
    protected: true,
  },
  {
    name: "Game3",
    layout: "/game",
    path: "game3",
    component: <Game3 />,
    protected: true,
  },
//   {
//     name: "Unauthorised",
//     layout: "/game",
//     path: "unauthorised",
//     component: <UnAuthorisedPage />,
//   },
  {
    name: "SendAlert",
    layout: "/instructor",
    path: "alert",
    component: <Alerts />,
    protected: true,
  },
  {
    name: "Client",
    layout: "/systemadmin",
    path: "client",
    component: <TenantIndexPage />,
    protected: true,
    children: [
      {
        name: "List",
        path: "list",
        component: <ClientListings />,
      },
      {
        name: "Create Client",
        path: "create",
        component: <CreateTenantForm />,
      },
      {
        name: "Editclient",
        path: "edit/:id",
        component: <CreateTenantForm />,
      },
      {
        name: "Viewclient",
        path: "view/:id",
        component: <ViewClient />,
      },
    ],
  },
  {
    name: "WorkShop",
    layout: "/systemadmin",
    path: "workshop",
    component: <WorkshopIndexPage />,
    protected: true,
    children: [
      {
        name: "List",
        path: "list",
        component: <WorkshopListing />,
      },
      {
        name: "Create Workshop",
        path: "create",
        component: <CreateWorkShopForm />,
      },
      {
        name: "Editworkshop",
        path: "edit/:id",
        component: <CreateWorkShopForm />,
      },
      {
        name: "Viewworkshop",
        path: "view/:id",
        component: <ViewWorkshop />,
      },
    ],
  },
  {
    name: "Configuration",
    layout: "/systemadmin",
    path: "configuration",
    component: <ConfigurationIndexPage />,
    protected: true,
    children: [
      {
        name: "List",
        path: "list",
        component: <ConfigurationListing />,
      },
      {
        name: "Create Configuration",
        path: "create",
        component: <CreateConfigurationForm />,
      },
      {
        name: "Editconfiguration",
        path: "edit/:id",
        component: <CreateConfigurationForm />,
      },
      {
        name: "Viewconfiguration",
        path: "view/:id",
        component: <ViewConfiguration />,
      },
    ],
  },
  {
    name: "User",
    layout: "/systemadmin",
    path: "user",
    component: <UserIndexPage />,
    protected: true,
    children: [
      {
        name: "List",
        path: "listing",
        component: <UserListing />,
      },
      {
        name: "Create User",
        path: "create",
        component: <CreateUserForms />,
      },
      {
        name: "Create User Roles",
        path: "roles",
        component: <PermissionManagement />,
      },
      {
        name: "Edituser",
        path: "edit/:id",
        component: <CreateUserForms />,
      },
      {
        name: "Viewuser",
        path: "view/:id",
        component: <ViewUser />,
      },
    ],
  },
];

export default mainRoutes;
