import React, { useEffect, useState } from "react";
import Loader from "components/loader/loader";
import { Flex, Box } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosClient from "config/apiClient";
import { useGameStore } from "store/store";
import "./leaderboard.css";
import { color } from "highcharts";
import { useCommonToast } from "components/toast/toast";

const InsightsBox = () => {
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [generalData, setgeneralData] = useState("");
  const showToast = useCommonToast();
  const location = useLocation();
  const navigate = useNavigate();
  const isPlayable = location.pathname.includes("play");
  let { Workshop } = useGameStore();
  useEffect(() => {
    setIsInitialLoading(true);
    getInsights();
  }, []);

  const getInsights = async () => {
    try {
      let response;

      if (isPlayable) {
        response = await axiosClient.get(
          `/workshop/insight/?acronym=${Workshop.acronym}&workshop=${Workshop.workshop_id}`
        );
      } else {
        response = await axiosClient.get(`/workshop/insight/`);
      }

      const updatedData = response.data.general;
      setgeneralData(updatedData);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.detail || error?.response?.data?.error;
      showToast("Error", errorMessage, "error");
      navigate("/leaderboard");
    } finally {
      setIsInitialLoading(false);
    }
  };

  return (
    <>
      {isInitialLoading ? (
        <Loader />
      ) : (
        <Box
          id="addclient-form"
          overflowX="auto"
          borderRadius={"20px"}
          display="flex"
          flexDirection="column"
          flex={1}
          fontFamily="Poppins"
          mx={{ base: "0vw", md: "18vw" }}
        >
          <Flex direction={"column"} flex={1}>
            <Flex
              bg={"TableHeaderbg"}
              justify="space-between"
              align={"center"}
              py={"10px"}
              px={"20px"}
              textColor="#0B389E"
              fontWeight={600}
              fontSize={"18px"}
            >
              Insights
            </Flex>
            <Flex
              bg={"Containerbg"}
              flex={1}
              py={"10px"}
              px={"20px"}
              direction={"column"}
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="column"
                height={"fit-content"}
                bg={"Containerbg"}
                padding={2}
                className={`text-yellowwt  px-3 py-1 text-left font-poppins capitalize text-[#FAC55F]`}
                borderRadius={6}
              >
                {generalData}
              </Box>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default InsightsBox;
