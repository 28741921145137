import { StateCreator } from "zustand";

const initialState = {
  Index: 0,
};

export interface Game3Data {
  price: number;
  time: string;
  ending_inventory: number;
  revenue: string;
  cumulative_revenue: string;
  beginning_inventory: number;
  first_time: string[];
  last_time: string[];
  rounds: number;
  max_rounds:number;
}
export interface gameTimeData {
  ui_time: string;
  backend_time: string;
  skip: boolean;
}
export interface SelectOptions {
  price: number;
}

export interface Game3Slice {
  game3data: Game3Data | null; // Initialize as null
  setGame3Data: (data: Game3Data | null) => void;
  timeData: Array<gameTimeData>;
  setTimeData: (data: Array<gameTimeData>) => void;
  optionData: [];
  setOptionData: (data: []) => void;
  Index: number;
  Incrementindex: () => void;
  ResetIndex: () => void;
  setIndex: (data: number) => void;
  Game3reset: () => void;
  IncrementRound: () => void;
  isGameOver: boolean;
  setIsGameOver: (value: boolean) => void;
}

type ImmerStateCreator<T> = StateCreator<
  T,
  [["zustand/immer", never], never],
  [],
  T
>;

export const createGame3Slice: ImmerStateCreator<Game3Slice> = (set) => ({
  timeData: null,
  setTimeData: (data) =>
    set((state) => {
      state.timeData = data;
    }),
  game3data: null,
  setGame3Data: (data) =>
    set((state) => {
      state.game3data = data;
    }),
  optionData: null,
  setOptionData: (data) =>
    set((state) => {
      state.optionData = data;
    }),
  setIndex: (data) =>
    set((state) => {
      state.Index = data;
    }),
  Index: initialState.Index,
  Incrementindex: () => set((state) => ({ Index: state.Index + 1 })),
  ResetIndex: () => set((state) => ({ Index: state.Index * 0 })),
  Game3reset: () => {
    set((state) => {
      state.game3data = null;
      state.timeData = null;
      state.optionData = null;
      state.Index = 0;
    });
  },
  IncrementRound: () => set((state) => ({ 
    game3data: { 
      ...state.game3data, 
      rounds: state.game3data.rounds + 1 
    } 
  })),
  isGameOver: false, 
  setIsGameOver: (value) =>
    set((state) => {
      state.isGameOver = value;
    }),
});
