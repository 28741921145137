import React from "react";
import {
  isAfter,
  isSameDay,
  startOfDay,
  setHours,
  setMinutes,
  addHours,
} from "date-fns";
import { timePickerConverter } from "helper/dateFormatter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTimePicker = ({
  field,
  formData,
  handleInputChange,
  isEditable,
  isTimePicker = true,
}) => {
  const isEndDateTime = field.id === "end_time";
  const isStartDateTime = field.id === "start_time";
  const currentDate = new Date();
  const selectedDate = formData[field.id] ? new Date(formData[field.id]) : null;
  const isDisabled = isEndDateTime && !formData?.start_time;

  const handleDateTimeChange = (date) => {
    if (date) {
      // if (date.getHours() === 0 && date.getMinutes() === 0) {
      //   const currentTime = new Date();

      //   if (isSameDay(date, currentTime)) {
      //     let minutes = currentTime.getMinutes();
      //     let hours = currentTime.getHours();

      //     minutes = Math.ceil(minutes / 15) * 15;

      //     if (minutes >= 60) {
      //       hours += Math.floor(minutes / 60);
      //       minutes = minutes % 60;
      //     }

      //     let adjustedDate = setHours(date, hours);
      //     adjustedDate = setMinutes(adjustedDate, minutes);

      //     handleInputChange(
      //       field.id,
      //       isTimePicker
      //         ? timePickerConverter(adjustedDate)
      //         : date.toISOString().split("T")[0]
      //     );
      //   } else {
      //     const adjustedDate = setHours(setMinutes(date, 0), 0); // Set to 9:00 AM for future dates
      //     handleInputChange(
      //       field.id,
      //       isTimePicker
      //         ? timePickerConverter(adjustedDate)
      //         : date.toISOString().split("T")[0]
      //     );
      //   }
      // } else {
      //   // Normal time selection flow
      //   handleInputChange(
      //     field.id,
      //     isTimePicker
      //       ? timePickerConverter(date)
      //       : date.toISOString().split("T")[0]
      //   );
      // }
      handleInputChange(field.id, date);
    } else {
      handleInputChange(field.id, "");
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    const minStartDate = new Date(currentDate.getTime() - 60000);
    if (isEndDateTime && formData.start_time) {
      const startDateTime = new Date(formData.start_time);
      return startDateTime < selectedDate;
    }

    return minStartDate < selectedDate;
  };

  const filterDate = (date) => {
    if (isStartDateTime) {
      return (
        isAfter(date, startOfDay(new Date())) ||
        isSameDay(date, startOfDay(new Date()))
      );
    }
    if (isEndDateTime && formData.start_time) {
      return (
        isAfter(date, formData.start_time) ||
        isSameDay(date, formData.start_time)
      );
    }
    return true;
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateTimeChange}
      showTimeSelect={isTimePicker}
      timeIntervals={15}
      timeCaption="Time"
      dateFormat={isTimePicker ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"}
      placeholderText={field.placeholder}
      filterTime={isTimePicker ? filterPassedTime : null}
      filterDate={filterDate}
      minDate={
        isEndDateTime && formData.start_time
          ? formData.start_datetime
          : isTimePicker
          ? timePickerConverter(currentDate)
          : currentDate
      }
      maxDate={
        isStartDateTime && formData.end_time ? formData.end_datetime : undefined
      }
      disabled={isDisabled}
      className={`w-full rounded p-2 ${isDisabled ? "" : "bg-[#FFFFFF4D]"}`}
      wrapperClassName={isDisabled ? "datepicker-disabled" : ""}
    />
  );
};

export default DateTimePicker;
